@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.testimonials {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.testimonialCard {
  display: grid;
  grid-template-columns: 130px auto;
  gap: 12px;
  cursor: pointer;

  .organizationName {
    text-align: right;
  }

  @media screen and (max-width: 1280px) {
    margin-bottom: 20px;
  }
}

.testimonialInfo {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.testimonialsHorizontal {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 380px) {
    gap: 8px;
  }

  .testimonialCard {
    width: 311px;
    height: 58px;
    white-space: break-spaces;
    min-width: 323px;
    border: 1px solid rgba(theme.$secondaryGrey, 0.3);
    padding: 8px 6px;

    @media screen and (max-width: 380px) {
      width: 290px;
      min-width: 290px;
      padding: 6px;
    }

    .organizationName {
      text-align: right;
    }
    .testimonialInfo {
      gap: 0;
    }

    .testimonialDescription {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3 !important;
      text-wrap: wrap;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
  }

  .hideBorder {
    border: none;
  }

  .linkWrapper {
    align-self: center;

    @media screen and (max-width: 1280px) {
      margin-top: -20px;
    }
  }
}

.modalTitle {
  font-size: 16px;
}

.testimonialModal {
  width: 463px !important;
  z-index: 100001;
  @media screen and (max-width: 900px) {
    height: 700px;
    width: 100% !important;
  }
  &__body {
    padding: 16px;
    &_description {
      &:before {
        content: open-quote;
      }
      &:after {
        content: close-quote;
      }
    }
    &_otherText {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
}
